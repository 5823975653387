import axios from 'axios'
const buildHeaders = token => ({
  Authorization: 'Bearer ' + token,
  'content-type': 'application/json'
})
export default function(baseUrl, token) {
  return {
    fetch: async (endpoint, request) => {
      const response = await axios.post(`${baseUrl}/${endpoint}`, request, {
        headers: buildHeaders(token)
      })
      return response.data
    }
  }
}
