import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

export default function(
  App,
  app,
  store,
  router,
  i18n,
  loginPage = 'Login',
  protectedPages = ['Home']
) {
  const callback = async user => {
    if (!app) {
      app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
      }).$mount('#app')
    }
    if (!process.env.VUE_APP_AUTH_ENABLED) return
    if (user) {
      const analytics = firebase.analytics()
      const { email } = user
      if (!email.includes('@anagraph.io')) {
        analytics.logEvent('login', { email })
      }
      await store.dispatch('auth/connect', true)
      await store.dispatch('auth/fetchToken')
      if (app.$route.query.to || !protectedPages.includes(app.$route.name)) {
        app.$router.replace(
          app.$route.query.to ? { name: app.$route.query.to } : { path: '/' }
        )
      }
    } else {
      app.$router.replace({
        name: loginPage,
        query: { to: app.$route.name }
      })
    }
  }
  return callback
}
