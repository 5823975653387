export function buildVectorLayerConfig(layerConfig) {
  const sourceLayer = layerConfig.url.split('/').pop()
  const { id, before, style } = layerConfig
  const paramsString = layerConfig.params
    ? Object.entries(layerConfig.params)
        .map(
          ([key, value]) =>
            `${key}=${typeof value !== 'object' ? value : value.join(',')}`
        )
        .join('&')
        .replace(/^/, '?')
    : ''
  const source = {
    type: 'vector',
    tiles: [`${layerConfig.url}/{z}/{x}/{y}.pbf${paramsString}`]
  }
  const layer = {
    id,
    type: layerConfig.type,
    source: id,
    'source-layer': sourceLayer,
    ...style
  }
  return { id, source, layer, before }
}
