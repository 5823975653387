import { onMounted } from '@vue/composition-api'
export default function({ mapRef, layer, property, action }) {
  onMounted(async () => {
    const map = mapRef.value.map
    map.on('click', layer, e => {
      const properties = e.features[0].properties
      action(properties[property])
    })
    map.on('mouseenter', layer, () => {
      map.getCanvas().style.cursor = 'pointer'
    })

    map.on('mouseleave', layer, () => {
      map.getCanvas().style.cursor = ''
    })
  })
}
