<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="color">
      <div
        class="color-rectangle"
        :style="{ 'background-color': item.color }"
      ></div>
      <div v-if="item.label">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { label: 'green label', color: 'green' },
        { label: 'red label', color: 'red' },
        { label: 'yellow label', color: 'yellow' },
        { label: 'white label', color: 'white' }
      ]
    }
  },
  computed: {
    formattedItems() {
      return this.items.map(({ label, color }) => ({
        label,
        color
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  display: flex;
  align-items: center;
  &-rectangle {
    width: 16px;
    height: 16px;
    border: solid lightgrey 1px;
    border-radius: 5px;
    margin: 6px 8px;
  }
}
</style>
