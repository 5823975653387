import stations from '@/data-config/layers/stations'
import { fillColorsExpr, buildStepExpr } from '@/data-config/expr-helper.js'
export default palette => [
  // {
  //   id: 'cp_non_localisé_ds_da',
  //   url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.cp_non_localisé_ds_da`,
  //   type: ''
  // },
  // {
  //   id: 'entrepot_clients',
  //   url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.entrepot_clients`,
  //   type: 'circle'
  // },
  // {
  //   id: 'livraison_da_cp_geom',
  //   url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.livraison_da_cp_geom`,
  //   type: ''
  // },
  {
    id: 'point_livraisons_da_station',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.point_livraisons_da_station`,
    type: 'fill'
  },
  {
    id: 'livraisons_par_ad',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.livraisons_par_ad`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': buildStepExpr('paquets_par_1000_habs', palette),
        'fill-opacity': 0.9
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'buffer_25km',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.buffer25km_test_now`,
    type: 'line',
    style: {
      paint: {
        'line-color': fillColorsExpr(stations),
        'line-width': 2
      }
    }
  },
  {
    id: 'buffer_50km',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.buffer50km_test_now`,
    type: 'line',
    style: {
      paint: {
        'line-color': fillColorsExpr(stations),
        'line-width': 2
      }
    }
  },
  {
    id: 'buffer_75km',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.buffer75km_test_now`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': fillColorsExpr(stations),
        'fill-opacity': 0.5
      }
    }
  },
  {
    id: 'area',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.station_trade_area`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': fillColorsExpr(stations),
        'fill-opacity': 0.5
      }
    }
  },
  {
    id: 'station_isochrone_driving_60',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.isochrones_stations_driving_60`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': '#1DC952',
        'fill-opacity': 1
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'station_isochrone_driving_30',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.isochrones_stations_driving_30`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': '#F6EA00',
        'fill-opacity': 1
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'station_isochrone_driving_15',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/public.isochrones_stations_driving_15`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': '#E20001',
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'canada_hexagons_r7_population',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.canada_hexagons_r7_population`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'population'], 0],
          '#FFFFFF',
          0,
          '#FFFFFF',
          500,
          '#ffbaba',
          5000,
          '#ff7b7b',
          15000,
          '#ff5252',
          50000,
          '#ff0000',
          96490,
          '#ff0000'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'nb_livraisons_par_hexagone',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.nb_livraisons_par_hexagone`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'somme_pkg_delivered'], 0],
          '#FFFFFF',
          1,
          '#FFFFFF',
          50,
          '#c4ddff',
          500,
          '#8bbdf6',
          5000,
          '#2c9aff',
          50000,
          '#264a87',
          273390,
          '#264a87'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'nb_livraisons_max_par_station_hexagone',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.nb_max_livraisons_par_station_hexagone`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': fillColorsExpr(stations, 'station_code'),
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'hexagone_r7_population_distance',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.hexagone_r7_population_distance_geom`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'match',
          ['to-number', ['get', 'population_distance'], 0],
          1,
          '#33a02c',
          2,
          '#e31a1c',
          3,
          '#ffef0f',
          4,
          '#ffffff',
          '#ffffff'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'hexagone_r7_population_livraison',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.hexagone_r7_population_livraison_geom`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'match',
          ['to-number', ['get', 'population_livraison'], 0],
          1,
          '#33a02c',
          2,
          '#fefe00',
          3,
          '#ff7f00',
          4,
          '#ffffff',
          '#ffffff'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'hexagons_r7_agrega_pop_r_25_km',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.hexagons_r7_agrega_pop_r_25_km`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'sum_pop'], 0],
          '#edf8fb',
          5000,
          '#b2e2e2',
          50000,
          '#66c2a4',
          250000,
          '#2ca25f',
          1000000,
          '#006d2c'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'hexagons_r7_agrega_livr_r_25_km',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/h3_unified.hexagons_r7_agrega_livr_r_25_km`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'sum_livr'], 0],
          '#ffc100',
          1,
          '#ffc100',
          2000000,
          '#ff9a00',
          4000000,
          '#ff7400',
          6000000,
          '#ff4d00',
          8000000,
          '#ff0000',
          10285638,
          '#ff0000'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'canada_buildingdbscan_3857',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/dbscan.canada_buildingdbscan_3857`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'match',
          ['to-number', ['get', 'eps'], 0],
          200,
          '#eeeeee',
          500,
          '#bbbbbb',
          5000,
          '#929292',
          '#929292'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'canada_demo_ad_dbscan_3857',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/dbscan.canada_demo_ad_dbscan_3857`,
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'match',
          ['to-number', ['get', 'eps'], 0],
          400,
          '#dfe3ee',
          700,
          '#71c7ec',
          10000,
          '#107dac',
          '#107dac'
        ],
        'fill-opacity': 0.7
      }
    },
    before: 'road_minor_case'
  }
]
