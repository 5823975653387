import { computed, watch } from '@vue/composition-api'

export default function({
  mapRef,
  layer,
  selected,
  targetProperty,
  defaultPosition
}) {
  const coordinates = computed(() => {
    return selected.value
      ? layer.source.data.features.find(
          f => f.properties[targetProperty] === selected.value
        ).geometry.coordinates
      : null
  })
  watch(selected, () => {
    const position = coordinates.value
      ? { center: coordinates.value, zoom: 7 }
      : defaultPosition
    mapRef.value.map.flyTo(position)
  })
  return { coordinates }
}
