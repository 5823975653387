import { auth } from './firebase'
export default {
  namespaced: true,
  state: {
    connected: false,
    token: null
  },
  mutations: {
    setConnected(state, isConnected) {
      state.connected = isConnected
    },
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {
    async login({ commit }, { email, password }) {
      const user = await auth.signInWithEmailAndPassword(email, password)
      commit('setConnected', true)
      return user
    },
    async logout({ commit }) {
      await auth.signOut()
      commit('setConnected', false)
    },
    async fetchToken({ commit }) {
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken()
        commit('setToken', token)
      }
    },
    connect({ commit }) {
      commit('setConnected', true)
    },
    async resetPassword(state, { email }) {
      await auth.sendPasswordResetEmail(email)
    }
  },
  getters: {
    profile: () => {
      if (auth.currentUser) {
        const { email, displayName, uid, isAnonymous } = auth.currentUser
        return { email, displayName, uid, isAnonymous }
      }
    }
  }
}
