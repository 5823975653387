<template>
  <div class="home">
    <left-panel-layout>
      <template v-slot:left-panel>
        <panel-content>
          <template v-slot:header>
            <h3>Intelcom: Diagnostic territorial</h3>
          </template>
          <template v-slot:content>
            <intelcom-cat-list
              :categories.sync="categories"
              @selectedvalue="chosenTool"
              @slidervalue="slideValue"
            >
              <div class="home__stations">
                <v-select
                  v-if="stationList"
                  :options="[{ id: null, label: 'Toutes' }, ...stationList]"
                  :selected.sync="selectedStation"
                />
              </div>
            </intelcom-cat-list>
          </template>
          <template v-slot:footer>
            <div style="font-style: italic; margin: 20px 0;">
              une réalisation <b>Anagraph</b>
            </div>
          </template>
        </panel-content>
      </template>
      <template v-slot:map>
        <Map
          v-if="layers"
          ref="map"
          :layers="layers"
          :visible-layers="visibleLayers"
          :selected-station.sync="selectedStation"
          :selectedtool="selectedtool"
          :slidervaluemap="slidervaluemap"
          @loaded="mapLoaded = true"
        />
      </template>
    </left-panel-layout>
    <panel v-show="selectedStation" class="home__right-panel">
      <station-infos :station="selectedStation" :tool="selectedtool" />
    </panel>
  </div>
</template>

<script>
import livraisonChloro from '@/data-config/chloros/livraisons_par_ad'
import Map from '@/components/map'
import LeftPanelLayout from '@anagraph/left-panel-layout'
import Panel from '@anagraph/panel'
import PanelContent from '@/components/panel-content.vue'
import categories from '@/data-config/categories'
// import LegendPanel from '@/commons/components/LegendPanel.vue'
import IntelcomCatList from '../components/intelcomCatList.vue'
import axios from 'axios'
import VSelect from '@/components/Select'
import { buildVectorLayerConfig } from '@/layer-helper'
import { fillColorsExpr } from '@/data-config/expr-helper.js'
import layerConfigList from '@/data-config/layers/layers'
import intelcomLayers from '@/data-config/layers/intelcom'
import stations from '@/data-config/layers/stations'

import StationInfos from '@/components/station-infos'
export default {
  name: 'Home',
  components: {
    LeftPanelLayout,
    Map,
    Panel,
    PanelContent,
    // LegendPanel,
    IntelcomCatList,
    VSelect,
    StationInfos
  },
  data() {
    return {
      selectedStation: null,
      legendOpen: false,
      // chloros: {
      //   density: densityChloro,
      //   livraison: livraisonChloro
      // },
      mapLoaded: false,
      layers: null,
      stationList: null,
      categories,
      visibleLayers: this.getVisibleLayers(categories),
      selectedtool: '',
      slidervaluemap: null
    }
  },
  watch: {
    categories: {
      deep: true,
      handler(categories) {
        this.visibleLayers = this.getVisibleLayers(categories)
      }
    }
  },
  async mounted() {
    const token = this.$store.state.auth.token
    if (!token) return
    const response = await axios.get(
      process.env.VUE_APP_BACKEND_URL + '/stations',
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    this.stationList = response.data.features.map(
      ({ properties: { station } }) => ({
        id: station,
        label: station
      })
    )
    const stationLayer = {
      id: 'stations',
      layer: {
        id: 'stations',
        source: 'stations',
        type: 'circle',
        paint: {
          'circle-radius': {
            stops: [
              [12, 5],
              [22, 360]
            ]
          },

          'circle-opacity': 0.8,
          'circle-color': fillColorsExpr(stations, 'station'),
          'circle-stroke-width': 1
        }
      },

      source: { type: 'geojson', data: response.data }
    }
    const vtLayers = [...layerConfigList, ...intelcomLayers(livraisonChloro)]
    this.layers = [...vtLayers.map(buildVectorLayerConfig), stationLayer]
  },
  methods: {
    getVisibleLayers() {
      return categories
        .map(c =>
          c.items
            .filter(i => i.enabled)
            .map(i => (i.type === 'layerset' ? i.layers : i.id))
        )
        .flat(2)
    },
    chosenTool(selectedvalue) {
      this.selectedtool = selectedvalue
    },
    // adjust the opacity by using the slider
    slideValue(value) {
      this.slidervaluemap = value
    }
  }
}
</script>
<style lang="scss">
i {
  color: #3d5bf3;
}

button:focus {
  outline: none;
}
.home {
  &__stations {
    padding: 20px 30px;
  }
  .panel-content__content {
    height: 75%;
  }
  &__right-panel.panel {
    position: absolute;
    width: 400px;
    z-index: 500;
    top: 50px;
    right: 50px;
  }
}
</style>
