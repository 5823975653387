export default [
  {
    id: 'intelcom',
    label: 'Intelcom',
    icon: 'fa-shipping-fast',
    open: true,
    items: [
      {
        id: 'stations',
        label: 'Stations',
        enabled: true
      }
      // {
      //   "id": "entrepot_clients",
      //   "label": "entrepot_clients",
      //   "enabled": false
      // },
      // {
      //   "id": "point_livraisons_da_station",
      //   "label": "point_livraisons_da_station",
      //   "enabled": false
      // },
    ]
  },
  {
    id: 'outils',
    label: 'Outil / Dimension',
    icon: 'fa-tools',
    open: true,
    items: [
      {
        id: 'radius',
        label: 'rayon',
        type: 'layerset',
        layers: ['buffer_25km', 'buffer_50km', 'buffer_75km'],
        enabled: false
      },
      {
        id: 'isochrones',
        label: 'isochrones',
        type: 'layerset',
        layers: [
          'station_isochrone_driving_60',
          'station_isochrone_driving_30',
          'station_isochrone_driving_15'
        ],
        enabled: false
      },
      {
        id: 'area',
        label: 'zone de chalandise',
        enabled: false
      }
    ]
  },
  // {
  //   id: 'transports',
  //   label: 'Transports',
  //   icon: 'fa-bus',
  //   items: [
  //     {
  //       id: 'metro-lines',
  //       label: 'Lignes de métro',
  //       enabled: false
  //     }
  //   ]
  // },
  {
    id: 'demography',
    label: 'Mesures',
    icon: 'fa-chart-bar',
    open: false,
    items: [
      {
        id: 'livraisons_par_ad',
        label: 'Livraisons par aire de diffusion',
        enabled: false
      },
      {
        id: 'densite-de-population',
        label: 'Densité de population',
        enabled: false
      },
      {
        id: 'canada_hexagons_r7_population',
        label: 'Hexagone de population',
        enabled: false
      },
      {
        id: 'nb_livraisons_par_hexagone',
        label: 'Livraisons par hexagone',
        enabled: false
      },
      {
        id: 'nb_livraisons_max_par_station_hexagone',
        label: 'Livraisons max par station par hexagone',
        enabled: false
      },
      {
        id: 'hexagone_r7_population_distance',
        label: 'Bivarie population/distance',
        enabled: false
      },
      {
        id: 'hexagone_r7_population_livraison',
        label: 'Bivarie population/livraison',
        enabled: false
      },
      {
        id: 'hexagons_r7_agrega_pop_r_25_km',
        label: 'Agregation population r 25km',
        enabled: false
      },
      {
        id: 'hexagons_r7_agrega_livr_r_25_km',
        label: 'Agregation livraison r 25km',
        enabled: false
      },
      {
        id: 'canada_buildingdbscan_3857',
        label: 'canada building dbscan',
        enabled: false
      },
      {
        id: 'canada_demo_ad_dbscan_3857',
        label: 'canada ad dbscan',
        enabled: false
      }
    ]
  }
]
