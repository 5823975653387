import { watch } from '@vue/composition-api'

export default function({ mapRef, layerId, selected, targetProperty }) {
  watch(selected, () => {
    const filter = selected.value
      ? ['match', ['get', targetProperty], [selected.value], true, false]
      : ['all']
    mapRef.value.map.setFilter(layerId, filter)
  })
}
