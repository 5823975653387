<template>
  <div>
    <div v-for="item in items" :key="item.id">
      <span v-if="typeof item.value === 'number'">
        <b>{{ $t(`stats.${item.id}`) }}: </b
        >{{ new Intl.NumberFormat('fr-CA').format(item.value) }}
      </span>
      <div v-else>
        <b>{{ $t(`stats.${item.id}`) }} </b>
        <ul>
          <li v-for="i in Object.keys(item.value)" :key="i">
            {{ i }}: {{ new Intl.NumberFormat('fr-CA').format(item.value[i]) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped></style>
