<template>
  <div class="station-infos">
    <div class="station-infos__header">
      <h2>{{ station }}</h2>
      <h3 v-if="mainStat">
        <b>{{ $t(`stats.${mainStat.id}`) }}: </b
        >{{ new Intl.NumberFormat('fr-CA').format(mainStat.value) }}
      </h3>
    </div>
    <div class="station-infos__body">
      <area-stats v-if="tool === 'area'" :stat-items="areaStats" />
      <radius-stats v-if="tool === 'radius'" :stat-items="radiusStats" />
      <isochrone-stats
        v-if="tool === 'isochrones'"
        :stat-items="isochronesStats"
      />
      <div v-if="tool === ''">Choisir un outil pour voir les statistiques</div>
    </div>
  </div>
</template>

<script>
import { toRefs, watch, ref, computed } from '@vue/composition-api'
import { getStats } from '@/data-config/data-helper'
import useDataFetcher from '@/commons/composable/useDataFetcher'
import statsFunctions from '@/data-config/statsFunctions'
import AreaStats from '@/components/area-stats'
import RadiusStats from '@/components/radius-stats'
import IsochroneStats from '@/components/isochrone-stats'
const { areaFunctions, radiusFunctions, isochronesFunctions } = statsFunctions
export default {
  components: {
    AreaStats,
    IsochroneStats,
    RadiusStats
  },
  props: {
    station: {
      type: String,
      default: null
    },
    tool: {
      type: String,
      default: 'area'
    }
  },
  setup(props, { root: { $store } }) {
    const { station } = toRefs(props)

    const areaStats = ref(null)
    const radiusStats = ref(null)
    const otherStats = ref(null)
    const typologyStats = ref(null)
    const isochronesStats = ref(null)
    const { fetch } = useDataFetcher(
      process.env.VUE_APP_BACKEND_URL,
      $store.state.auth.token
    )
    watch(station, async () => {
      if (!station.value) return
      areaStats.value = await getStats(fetch, areaFunctions, {
        station: station.value
      })

      const radiusList = [25, 50, 75]
      radiusStats.value = await Promise.all(
        radiusList.map(radius =>
          getStats(fetch, radiusFunctions, {
            station: station.value,
            echelle: `${radius}km`
          }).then(stats => ({
            radius,
            stats
          }))
        )
      )
      const isochrones = [15, 30, 60]
      isochronesStats.value = await Promise.all(
        isochrones.map(isochrone =>
          getStats(fetch, isochronesFunctions, {
            station: station.value,
            echelle: `${isochrone}min`
          }).then(stats => ({
            isochrone,
            stats
          }))
        )
      )
    })

    const mainStat = computed(() =>
      areaStats.value?.find(s => s.id === 'nb_packages_delivered_par_station')
    )
    return {
      areaStats,
      radiusStats,
      isochronesStats,
      otherStats,
      typologyStats,
      mainStat
    }
  }
}
</script>

<style lang="scss" scoped>
.station-infos {
  max-height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  ul {
    margin: 10px 0;
    padding: 0 40px;
  }
  padding: 20px;
  font-size: smaller;
  text-align: left;
  &__body {
    overflow: auto;
  }
}
</style>
