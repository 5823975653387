// export default {
//   fr: {
//     stats: {
//       distribution_typologie_logement_par_rayon_par_station:
//         'Typologie de logement',
//       nb_livraisons_pondere_rayon: 'Nombre de livraison dans le rayon',
//       nb_livraisons_specific_station_pondere_rayon:
//         'Livraisons dans un rayon de 50km dans la ZC',
//       nbre_menages_par_rayon_stations: 'Nombre de ménages dans le rayon',
//       nbre_population_par_rayon_stations: "Nombre d'habitant dans le rayon",
//       distribution_typologie_logement_par_area_par_station:
//         'Typologie de logement',
//       nb_habitants_par_area_par_station: "Nombre d'habitant dans la ZC",
//       nb_menage_par_area_par_station: 'Nombre de ménage dans la ZC',
//       nb_packages_delivered_par_station: 'Nombre de livraisons dans la ZC',
//       nb_livraisons_pondere_iso: "Nombre de livraisons dans l'isochrone",
//       nbre_population_par_iso_stations: "Nombre d'habitant dans l'isochrone",
//       nb_livraisons_specific_station_pondere_iso_distance:
//         "Livraisons dans l'isochrone ",
//       nbre_menage_par_iso_stations: "Nombre de ménage dans l'isochrone ",
//       typo_logement_par_iso_stations: 'Typologie de logement'
//     }
//   }
// }

/* 
  generated from copy paste of 2 first columns of https://docs.google.com/spreadsheets/d/1BDTwvCwqeD1faZB2qOAiy84SzhbbD_j-EjxmD2rcF8g/edit#gid=0
*/

const data = `
distribution_typologie_logement_par_rayon_par_station	Typologie de logement
nb_livraisons_pondere_rayon	Livraisons totales dans le rayon
nb_livraisons_specific_station_pondere_rayon	Livraisons
nbre_menages_par_rayon_stations	Ménages
nbre_population_par_rayon_stations	Population
distribution_typologie_logement_par_area_par_station	Typologie de logement
nb_habitants_par_area_par_station	Population
nb_menage_par_area_par_station	Ménages
nb_packages_delivered_par_station	Livraisons
nb_livraisons_pondere_iso	Nombre de livraisons toutes stations confondues dans l'isochrone
nbre_population_par_iso_stations	Population
nb_livraisons_specific_station_pondere_iso_distance	Livraisons
nbre_menage_par_iso_stations	Ménages
`
export default {
  fr: {
    stats: Object.fromEntries(data.split('\n').map(l => l.split('\t')))
  }
}
