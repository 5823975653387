<template>
  <div>
    <h3>Zone de chalandise</h3>
    <stats-list :items="statItems" />
  </div>
</template>

<script>
import StatsList from '@/components/stats-list'
export default {
  components: {
    StatsList
  },
  props: {
    statItems: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped></style>
