<template>
  <layer-category-list
    :categories="categories.map(cat => ({ ...cat, open: cat.open }))"
  >
    <template v-slot:intelcom>
      <layer-list :items="categories.find(c => c.id === 'intelcom').items">
        <template v-slot:stations>
          <toggle-label :value="stationsLayer.enabled" @input="toggleStations">
            {{ stationsLayer.label }}
          </toggle-label>
          <slot v-if="stationsLayer.enabled" />
        </template>
        <template v-slot:livraisons_par_ad>
          <toggle-label
            :value="livraisonLayer.enabled"
            @input="toggleLivraison"
          >
            {{ livraisonLayer.label }}
          </toggle-label>
          <slot v-if="stationsLayer.enabled" />
        </template>
      </layer-list>
    </template>
    <template v-slot:outils>
      <div class="tool-select">
        <v-select
          :selected.sync="selected"
          :options="[
            bufferLayer,
            isochronesLayer,
            tradeAreaLayer,
            { id: null, label: 'Aucun' }
          ]"
        />
        <div class="slidecontainer">
          opacité
          <slider
            v-model="slidervalue"
            :step="0.1"
            :min="0"
            :max="1"
            @input="$emit('slidervalue', $event)"
          />
        </div>
      </div>
    </template>
    <template v-slot:demography>
      <layer-list :items="categories.find(c => c.id === 'demography').items">
        <template v-slot:densite-de-population>
          <toggle-label
            :value="demographieLayer.enabled"
            @input="toggleDemographie"
          >
            {{ demographieLayer.label }}
          </toggle-label>
          <c-legend
            v-if="demographieLayer.enabled"
            title="densité de population"
            :items="chloros.density"
            legendtype="C-Legend"
          />
        </template>
        <template v-slot:livraisons_par_ad>
          <toggle-label
            :value="livraisonLayer.enabled"
            @input="toggleLivraison"
          >
            {{ livraisonLayer.label }}
          </toggle-label>
          <c-legend
            v-if="livraisonLayer.enabled"
            title="livraisons_par_ad"
            :items="chloros.livraison"
            legendtype="C-Legend"
          />
        </template>
        <template v-slot:canada_hexagons_r7_population>
          <toggle-label
            :value="hexapopLayer.enabled"
            @input="toggleHexaPopulation"
          >
            {{ hexapopLayer.label }}
          </toggle-label>
          <c-legend
            v-if="hexapopLayer.enabled"
            title="canada_hexagons_r7_population"
            :items="chloros.hexapop"
            legendtype="C-Legend"
          />
        </template>
        <template v-slot:nb_livraisons_par_hexagone>
          <toggle-label
            :value="livrHexaLayer.enabled"
            @input="toggleLivrHexagone"
          >
            {{ livrHexaLayer.label }}
          </toggle-label>
          <c-legend
            v-if="livrHexaLayer.enabled"
            title="nb_livraisons_par_hexagone"
            :items="chloros.livrhexagone"
            legendtype="C-Legend"
          />
        </template>
        <template v-slot:hexagone_r7_population_distance>
          <toggle-label
            :value="popDistanceLayer.enabled"
            @input="togglePopDistance"
          >
            {{ popDistanceLayer.label }}
          </toggle-label>
          <c-legend
            v-if="popDistanceLayer.enabled"
            title="hexagone_r7_population_distance"
            :items="chloros.bivapopuladistance"
            legendtype="Category-Legend"
          />
        </template>
        <template v-slot:hexagone_r7_population_livraison>
          <toggle-label
            :value="popLivraisonLayer.enabled"
            @input="togglePopLivraison"
          >
            {{ popLivraisonLayer.label }}
          </toggle-label>
          <c-legend
            v-if="popLivraisonLayer.enabled"
            title="hexagone_r7_population_livraison"
            :items="chloros.bivapopulivraison"
            legendtype="Category-Legend"
          />
        </template>
        <template v-slot:hexagons_r7_agrega_pop_r_25_km>
          <toggle-label
            :value="agregPop25kmLayer.enabled"
            @input="toggleAregPopulation"
          >
            {{ agregPop25kmLayer.label }}
          </toggle-label>
          <c-legend
            v-if="agregPop25kmLayer.enabled"
            title="hexagons_r7_agrega_pop_r_25_km"
            :items="chloros.agregpop25km"
            legendtype="C-Legend"
          />
        </template>
        <template v-slot:hexagons_r7_agrega_livr_r_25_km>
          <toggle-label
            :value="agregLivr25kmLayer.enabled"
            @input="toggleAregLivraison"
          >
            {{ agregLivr25kmLayer.label }}
          </toggle-label>
          <c-legend
            v-if="agregLivr25kmLayer.enabled"
            title="hexagons_r7_agrega_livr_r_25_km"
            :items="chloros.agreglivr25km"
            legendtype="C-Legend"
          />
        </template>
        <template v-slot:canada_buildingdbscan_3857>
          <toggle-label
            :value="builddbScanLayer.enabled"
            @input="toggleBuildDbScan"
          >
            {{ builddbScanLayer.label }}
          </toggle-label>
          <c-legend
            v-if="builddbScanLayer.enabled"
            title="canada_buildingdbscan_3857"
            :items="chloros.builddbscan"
            legendtype="Category-Legend"
          />
        </template>
        <template v-slot:canada_demo_ad_dbscan_3857>
          <toggle-label :value="addbScanLayer.enabled" @input="toggleAdDbScan">
            {{ addbScanLayer.label }}
          </toggle-label>
          <c-legend
            v-if="addbScanLayer.enabled"
            title="canada_demo_ad_dbscan_3857"
            :items="chloros.addbscans"
            legendtype="Category-Legend"
          />
        </template>
      </layer-list>
    </template>
  </layer-category-list>
</template>

<script>
import LayerCategoryList from '@anagraph/layer-category-list'
import Slider from '@anagraph/slider-control'
import VSelect from '@/components/Select'
import LayerList from '@anagraph/layer-category-list/layer-list'
import ToggleLabel from '@anagraph/layer-category-list/toggle-label'
import CLegend from '@/components/legend'
import densityChloro from '@/data-config/chloros/densite-de-population'
import livraisonChloro from '@/data-config/chloros/livraisons_par_ad'
import hexagonePop from '@/data-config/chloros/hexagone_population'
import livraisonHexagone from '@/data-config/chloros/livraison_par_hexagone'
import bivPopDistance from '@/data-config/chloros/population-distance'
import bivPopLivraison from '@/data-config/chloros/population-livraison'
import agregpop25km from '@/data-config/chloros/agregation_pop_25_km'
import agreglivr25km from '@/data-config/chloros/agregation_livr_25_km'
import builddbscan from '@/data-config/chloros/buiding_dbscan'
import addbscan from '@/data-config/chloros/ad_dbscan'

export default {
  components: {
    LayerCategoryList,
    LayerList,
    ToggleLabel,
    CLegend,
    Slider,
    VSelect
  },
  props: {
    categories: {
      type: Array,
      default: () =>
        [...Array(4).keys()].map(num => ({
          id: `category-${num}`,
          label: `Category ${num}`,
          items: [...Array(4).keys()].map(numItem => ({
            id: `item-${num}-${numItem}`,
            label: `Item ${num} ${numItem}`
          }))
        }))
    }
  },
  data() {
    return {
      chloros: {
        density: densityChloro,
        livraison: livraisonChloro,
        hexapop: hexagonePop,
        livrhexagone: livraisonHexagone,
        bivapopuladistance: bivPopDistance,
        bivapopulivraison: bivPopLivraison,
        agregpop25km: agregpop25km,
        agreglivr25km: agreglivr25km,
        builddbscan: builddbscan,
        addbscans: addbscan
      },
      selected: null,
      slidervalue: 0.5
    }
  },
  computed: {
    stationsLayer() {
      return this.categories
        .find(c => c.id === 'intelcom')
        .items.find(i => i.id === 'stations')
    },
    livraisonLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'livraisons_par_ad')
    },
    demographieLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'densite-de-population')
    },
    hexapopLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'canada_hexagons_r7_population')
    },
    livrHexaLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'nb_livraisons_par_hexagone')
    },
    popDistanceLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'hexagone_r7_population_distance')
    },
    popLivraisonLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'hexagone_r7_population_livraison')
    },
    agregPop25kmLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'hexagons_r7_agrega_pop_r_25_km')
    },
    agregLivr25kmLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'hexagons_r7_agrega_livr_r_25_km')
    },
    builddbScanLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'canada_buildingdbscan_3857')
    },
    addbScanLayer() {
      return this.categories
        .find(c => c.id === 'demography')
        .items.find(i => i.id === 'canada_demo_ad_dbscan_3857')
    },
    bufferLayer() {
      return this.categories
        .find(c => c.id === 'outils')
        .items.find(i => i.id === 'radius')
    },
    isochronesLayer() {
      return this.categories
        .find(c => c.id === 'outils')
        .items.find(i => i.id === 'isochrones')
    },
    tradeAreaLayer() {
      return this.categories
        .find(c => c.id === 'outils')
        .items.find(i => i.id === 'area')
    }
  },
  watch: {
    selected: function(val) {
      val === 'radius'
        ? (this.bufferLayer.enabled = true)
        : (this.bufferLayer.enabled = false)
      val === 'isochrones'
        ? (this.isochronesLayer.enabled = true)
        : (this.isochronesLayer.enabled = false)
      val === 'area'
        ? (this.tradeAreaLayer.enabled = true)
        : (this.tradeAreaLayer.enabled = false)
      this.$emit('selectedvalue', val)
    }
  },
  methods: {
    toggleLivraison() {
      this.livraisonLayer.enabled = !this.livraisonLayer.enabled
    },
    toggleDemographie() {
      this.demographieLayer.enabled = !this.demographieLayer.enabled
    },
    toggleHexaPopulation() {
      this.hexapopLayer.enabled = !this.hexapopLayer.enabled
    },
    toggleLivrHexagone() {
      this.livrHexaLayer.enabled = !this.livrHexaLayer.enabled
    },
    togglePopDistance() {
      this.popDistanceLayer.enabled = !this.popDistanceLayer.enabled
    },
    togglePopLivraison() {
      this.popLivraisonLayer.enabled = !this.popLivraisonLayer.enabled
    },
    toggleAregPopulation() {
      this.agregPop25kmLayer.enabled = !this.agregPop25kmLayer.enabled
    },
    toggleAregLivraison() {
      this.agregLivr25kmLayer.enabled = !this.agregLivr25kmLayer.enabled
    },
    toggleBuildDbScan() {
      this.builddbScanLayer.enabled = !this.builddbScanLayer.enabled
    },
    toggleAdDbScan() {
      this.addbScanLayer.enabled = !this.addbScanLayer.enabled
    },
    toggleStations() {
      this.stationsLayer.enabled = !this.stationsLayer.enabled
    }
  }
}
</script>

<style lang="scss">
.tool-select {
  padding: 20px 20px;
  padding-bottom: 60px;
  .slidecontainer {
    margin: 10px;
    text-align: left;
  }
}
</style>
