<template>
  <div class="legend">
    <component :is="legendtype" :items="items"></component>
  </div>
</template>

<script>
import CLegend from '@/commons/components/chloropleth-legend'
import CategoryLegend from '../commons/components/category-legend.vue'
export default {
  components: {
    'C-Legend': CLegend,
    'Category-Legend': CategoryLegend
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    items: {
      type: Array,
      default: () => []
    },
    legendtype: {
      type: String,
      default: 'Legend'
    }
  }
}
</script>

<style lang="scss" scoped>
.legend {
  padding: 12px 0;
  text-align: left;
  &__title {
    font-weight: 600;
    padding: 8px 0;
  }
}
</style>
