const generateColor = index => '#' + ((index + 1) / 100).toString(16).substr(-6)
const generateStationsCases = (stations, property = 'deliv_station') =>
  stations.reduce((expr, station, index) => {
    const exprPart = [['==', ['get', property], station], generateColor(index)]
    return [...expr, ...exprPart]
  }, [])
export const fillColorsExpr = (stations, property) => [
  'case',
  ...generateStationsCases(stations, property),
  'lightblue'
]
const buildPaletteSteps = palette =>
  palette
    .map(item => [item.color, item.max])
    .flat()
    .filter(Boolean)
export const buildStepExpr = (property, palette) => [
  'step',
  ['to-number', ['get', property], 0],
  ...buildPaletteSteps(palette)
]
export const buildGeoDivergentPalette = (features, divergentPalette) => {
  // get centroid
}
