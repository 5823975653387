import mapboxgl from 'mapbox-gl'
import { onMounted } from '@vue/composition-api'
const defaultHtml = properties =>
  Object.entries(properties)
    .filter(([key, value]) => !['fid', 'adidu', 'h3_id'].includes(key))
    .map(([key, value]) => `<b>${key}: </b>${value}`)
    .join('<br>')
export default function({ mapRef, layer, htmlBuilder = defaultHtml }) {
  onMounted(async () => {
    const map = mapRef.value.map
    map.on('click', layer, e => {
      const properties = e.features[0].properties
      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(htmlBuilder(properties))
        .addTo(map)
    })
    map.on('mouseenter', layer, () => {
      map.getCanvas().style.cursor = 'pointer'
    })

    map.on('mouseleave', layer, () => {
      map.getCanvas().style.cursor = ''
    })
  })
}
