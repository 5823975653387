const areaFunctions = [
  'nb_habitants_par_area_par_station',
  'nb_menage_par_area_par_station',
  'nb_packages_delivered_par_station',
  'distribution_typologie_logement_par_area_par_station'
]
const radiusFunctions = [
  // 'nb_livraisons_pondere_rayon',
  'nbre_population_par_rayon_stations',
  'nbre_menages_par_rayon_stations',
  'nb_livraisons_specific_station_pondere_rayon',
  'distribution_typologie_logement_par_rayon_par_station'
]
const isochronesFunctions = [
  // 'nb_livraisons_pondere_iso',
  'nbre_population_par_iso_stations',
  'nbre_menage_par_iso_stations',
  'nb_livraisons_specific_station_pondere_iso_distance'
]

const otherFunctions = ['sum_distance_oursins']

export default {
  areaFunctions,
  radiusFunctions,
  isochronesFunctions,
  otherFunctions
}
