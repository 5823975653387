<template>
  <div>
    <gl-map
      ref="mapRef"
      :init-map-options="options"
      :init-map-position="position"
      @loaded="$emit('loaded')"
    />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import 'mapbox-gl/dist/mapbox-gl.css'

import { useToggleLayers } from '@anagraph/composable-map'
import useMapGeometryNavigation from '../commons/composable/useMapGeometryNavigation'
import useMapLayerFilter from '../commons/composable/useMapLayerFilter'
import useMapboxPopup from '../commons/composable/useMapboxPopup'
import useFeatureSelection from '../commons/composable/useFeatureSelection'
// import layerOpacity from '../commons/composable/layerOpacity'
import { toRefs, shallowRef, watch } from '@vue/composition-api'
import { mapbox as mapboxConfig } from '@/config'
export default {
  components: {
    GlMap
  },
  props: {
    layers: {
      type: Array,
      default: () => []
    },
    visibleLayers: {
      type: Array,
      default: () => []
    },
    selectedStation: {
      type: String,
      default: 'none'
    },
    slidervaluemap: {
      type: Number,
      default: null
    }
  },
  setup(props, { root: { $store }, emit }) {
    const transformRequest = (url, resourceType) => {
      if (
        resourceType === 'Tile' &&
        url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
      ) {
        return {
          url: url,
          headers: { Authorization: `Bearer ${$store.state.auth.token}` }
        }
      }
    }
    const options = { transformRequest, minZoom: 1 }
    const { selectedStation, layers, visibleLayers, slidervaluemap } = toRefs(
      props
    )
    const mapRef = shallowRef(null)
    useToggleLayers({
      mapRef,
      layers,
      visibleLayers
    })
    const { coordinates } = useMapGeometryNavigation({
      mapRef,
      layer: layers.value.find(l => l.id === 'stations'),
      selected: selectedStation,
      targetProperty: 'station',
      defaultPosition: mapboxConfig.position
    })

    const isochronesLayers = [
      'station_isochrone_driving_60',
      'station_isochrone_driving_30',
      'station_isochrone_driving_15'
    ]
    isochronesLayers.forEach(layerId =>
      useMapLayerFilter({
        mapRef,
        layerId,
        selected: selectedStation,
        targetProperty: 'station'
      })
    )
    useFeatureSelection({
      mapRef,
      layer: 'stations',
      property: 'station',
      action: data => emit('update:selectedStation', data)
    })
    useMapboxPopup({ mapRef, layer: 'livraisons_par_ad' })
    useMapboxPopup({
      mapRef,
      layer: 'station_trade_area',
      htmlBuilder: properties =>
        `<b>Main origin station</b><br>${properties.deliv_station}`
    })

    const popupLayers = [
      'canada_hexagons_r7_population',
      'nb_livraisons_par_hexagone',
      'nb_livraisons_max_par_station_hexagone',
      'hexagone_r7_population_distance',
      'hexagone_r7_population_livraison',
      'hexagons_r7_agrega_pop_r_25_km',
      'hexagons_r7_agrega_livr_r_25_km',
      'canada_buildingdbscan_3857',
      'canada_demo_ad_dbscan_3857'
    ]
    popupLayers.map(layer => useMapboxPopup({ mapRef, layer }))
    watch(
      slidervaluemap,
      () => {
        const toolLayers = [
          'buffer_75km',
          'station_isochrone_driving_60',
          'station_isochrone_driving_30',
          'station_isochrone_driving_15',
          'area'
        ]
        const map = mapRef.value.map
        toolLayers.forEach(layer => {
          map.setPaintProperty(
            layer,
            'fill-opacity',
            parseFloat(slidervaluemap.value)
          )
        })
      },
      { deep: true }
    )

    return {
      mapRef,
      options,
      position: mapboxConfig.position,
      coordinates
    }
  }
}
</script>

<style lang="scss" scoped></style>
