import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import config from '@/config'
let auth
if (config?.firebase.apiKey && !firebase.apps.length) {
  firebase.initializeApp(config.firebase)
  if (config.firebase.measurementId) {
    firebase.analytics()
  }
  auth = firebase.auth()
}

export { auth }
