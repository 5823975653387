<template>
  <div>
    <login />
  </div>
</template>

<script>
import Login from '../commons/auth/Login'
export default {
  components: {
    Login
  }
}
</script>

<style lang="scss" scoped></style>
